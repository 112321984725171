export const defaultOptions = {
  fillOpacity: 0.05,
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
}

export const closeOptions = {
  ...defaultOptions,
  zIndex: 3,
  strokeColor: "#8bc34a",
  fillColor: "#8bc34a",
}

export const middleOptions = {
  ...defaultOptions,
  zIndex: 2,
  strokeColor: "#fbc02d",
  fillColor: "#fbc02d",
}

export const farOptions = {
  ...defaultOptions,
  zIndex: 1,
  strokeColor: "#ff5252",
  fillColor: "##ff5252",
}
