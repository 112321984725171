import { Button, FormControlLabel, FormGroup, Typography } from "@mui/material"
import { Form, Formik, useField } from "formik"
import { FC, useState } from "react"
import * as Yup from "yup"
import { useDuplicateProjectMutation } from "../../../graphql/generated/client-types-and-hooks"
import { ModalProps } from "../../../hooks/useModalProps"
import { Checkbox } from "../../Formik/Checkbox"
import { TextField } from "../../Formik/TextField"
import { ModalBody } from "../../Modals/ModalBody"
import { ModalFooter } from "../../Modals/ModalFooter"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"

type DuplicateProjectModalProps = {
  modalProps: ModalProps
  onSuccess?: () => void
  projectId: string
  projectName: string
}

type FormValues = {
  projectId: string
  projectName: string
}

export const DuplicateProjectModal: FC<DuplicateProjectModalProps> = ({
  modalProps,
  onSuccess,
  projectId,
  projectName,
}) => {
  const [, duplicateProjectMutation] = useDuplicateProjectMutation()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (values: FormValues) => {
    try {
      const { error } = await duplicateProjectMutation(values)
      if (error) throw new Error("There was an error duplicating this project")
      modalProps.handleCloseModal()
      successSnack("Successfully duplicated project")
      onSuccess?.()
    } catch (error) {
      errorSnack(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={{
        projectId,
        projectName: `Copy of ${projectName}`,
        includeTasks: true,
        includeReportingUnits: true,
        includeAdditionalUnits: true,
      }}
      validationSchema={Yup.object().shape({
        projectId: Yup.string().trim().required("Required").label("Project Id"),
        projectName: Yup.string().trim().required("Required").label("Project Name"),
        includeTasks: Yup.boolean(),
        includeReportingUnits: Yup.boolean(),
        includeAdditionalUnits: Yup.boolean(),
      })}
      onSubmit={onSubmit}
    >
      {({}) => {
        return (
          <Form className={"h-full flex flex-col"}>
            <ModalBody className="min-h-[200px]">
              <Typography className="mb-8">
                Name the duplicate project and select which project attributes you would like to duplicate
              </Typography>
              <TextField fullWidth name="projectName" label="Project name" />

              <ProjectAttributes />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" variant="outlined" onClick={modalProps.handleCloseModal} disabled={isLoading}>
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained" disabled={isLoading}>
                Save Project
              </Button>
            </ModalFooter>
          </Form>
        )
      }}
    </Formik>
  )
}

const ProjectAttributes = () => {
  const [{ value: includeTasks }] = useField("includeTasks")
  const [, , { setValue: setIncludeReportingUnits }] = useField("includeReportingUnits")
  const [, , { setValue: setIncludeAdditionalUnits }] = useField("includeAdditionalUnits")

  return (
    <FormGroup>
      <Typography variant="h6" className="mb-4">
        Project Attributes
      </Typography>

      <FormControlLabel
        control={
          <Checkbox
            name="includeTasks"
            onChange={(_e, checked) => {
              setIncludeReportingUnits(checked)
              setIncludeAdditionalUnits(checked)
            }}
            defaultChecked
          />
        }
        label="Task List"
      />
      {includeTasks && (
        <FormControlLabel
          className="ml-3"
          control={<Checkbox name="includeReportingUnits" defaultChecked />}
          label="Reporting Units"
        />
      )}
      {includeTasks && (
        <FormControlLabel
          className="ml-3"
          control={<Checkbox name="includeAdditionalUnits" defaultChecked />}
          label="Additional Units"
        />
      )}
    </FormGroup>
  )
}
