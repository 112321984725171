import { Autocomplete, Box, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"
import parse from "autosuggest-highlight/parse"
import { useField } from "formik"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { BiMap, BiSearch } from "react-icons/bi"
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete"
import { AutocompletePrediction, LatLongLiteral } from "../../types/Maps"
import { METERS_PER_MILE } from "./helpers"

const distanceOptions = [
  { label: "0.5 Mile", value: Number((0.5 * METERS_PER_MILE).toFixed(0)) },
  { label: "1 Mile", value: Number((1 * METERS_PER_MILE).toFixed(0)) },
  { label: "2 Miles", value: Number((2 * METERS_PER_MILE).toFixed(0)) },
  { label: "3 Miles", value: Number((3 * METERS_PER_MILE).toFixed(0)) },
  { label: "4 Miles", value: Number((4 * METERS_PER_MILE).toFixed(0)) },
  { label: "5 Miles", value: Number((5 * METERS_PER_MILE).toFixed(0)) },
]

const defaultPlaceholder = "Search for a location"

export const LocationSearch: FC<{
  pinAddressOrLocation: string
  setLocation: (potion: LatLongLiteral | undefined) => void
  radius: number
  setRadius: Dispatch<SetStateAction<number>>
}> = ({ pinAddressOrLocation, setLocation, radius, setRadius }) => {
  const [options, setOptions] = useState<(AutocompletePrediction | undefined)[]>([])
  const [placeholder, setPlaceholder] = useState(defaultPlaceholder)
  const [inputValue, setInputValue] = useState("")

  const {
    setValue: setPlacesSearchValue,
    suggestions: { data, loading, status },
    clearSuggestions,
  } = usePlacesAutocomplete()

  const [field, _meta, helpers] = useField("location")

  useEffect(() => {
    if (loading || status !== "OK") {
      return
    }

    if (data.length > 0 && data !== options) {
      setOptions(data)
    }
  }, [data, loading, status, setOptions, options])

  useEffect(() => {
    if (!pinAddressOrLocation && placeholder !== defaultPlaceholder) {
      setPlaceholder(defaultPlaceholder)
      return
    }

    if (pinAddressOrLocation) {
      setPlacesSearchValue(pinAddressOrLocation)
      setPlaceholder(pinAddressOrLocation)
    }
  }, [placeholder, setPlaceholder, pinAddressOrLocation, setPlacesSearchValue])

  const handleChange = async (event: any, newValue: AutocompletePrediction | null | undefined) => {
    reset()
    event.target.blur()

    if (!newValue) {
      setLocation(undefined)
      return
    }

    const results = await getGeocode({ address: newValue?.description })
    const coordinates = await getLatLng(results[0])

    setLocation(coordinates)
  }

  const isCoordinates = (value: string) => {
    return value.includes(",") && value.split(",").every((v) => !isNaN(Number(v)))
  }

  const handleRadiusChange = (e: SelectChangeEvent<number>) => {
    setRadius(Number(e.target.value))
    helpers.setValue({ ...field.value, radius: e.target.value })
  }

  const reset = () => {
    setPlacesSearchValue("")
    setInputValue("")
    setOptions([])
    clearSuggestions()
  }

  return (
    <div className="flex w-full justify-between relative top-[20px]">
      <div className="bg-white w-full rounded mx-2">
        <Autocomplete
          id="google-map"
          fullWidth
          getOptionLabel={(option) => (typeof option === "string" ? option : option?.description || "")}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          inputValue={inputValue}
          noOptionsText="No locations"
          clearOnBlur
          onChange={handleChange}
          onInputChange={async (_e, newInputValue, reason) => {
            if (reason === "reset") {
              reset()
              return
            }

            let newValue = newInputValue

            // input and placeSearchValue will be different when entering numbers that look like coordinates
            setInputValue(newValue)

            if (isCoordinates(newInputValue)) {
              const [lat, lng]: number[] = newInputValue.split(",").map((value) => Number(value))

              if (lat && lng) {
                const places = await getGeocode({ location: { lat, lng } })

                setLocation({ lat, lng })
                newValue = places[0].formatted_address
              }
            }
            setPlacesSearchValue(newValue)
          }}
          popupIcon={<BiSearch />}
          sx={{ "& .MuiAutocomplete-popupIndicatorOpen": { transform: "rotate(0)" } }}
          renderInput={(params) => <TextField {...params} placeholder={placeholder} fullWidth />}
          renderOption={(props, option) => {
            // This highlights the parts of the text that match the search term
            const matches = option?.structured_formatting?.main_text_matched_substrings || []

            const parts = option
              ? parse(
                  option?.structured_formatting?.main_text,
                  matches.map((match: any) => [match.offset, match.offset + match.length])
                )
              : []

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: "flex", width: 44 }}>
                    <BiMap />
                  </Grid>
                  <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                    {parts.map((part, index) => (
                      <Box key={index} component="span" sx={{ fontWeight: part.highlight ? "bold" : "regular" }}>
                        {part.text}
                      </Box>
                    ))}
                    <Typography variant="body2" color="text.secondary">
                      {option?.structured_formatting?.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            )
          }}
        />
      </div>

      {/* Radius dropdown */}
      <div className="bg-white rounded mr-2">
        <Select value={radius} onChange={handleRadiusChange} sx={{ width: 200 }}>
          {distanceOptions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  )
}
