import { Button, TextFieldProps } from "@mui/material"
import { useField } from "formik"
import { FC } from "react"
import { FaMagic } from "react-icons/fa"
import { TextField } from "./TextField"

type Props = {
  name: string
  generateValue: () => string
}

export const MuiGenerateInput: FC<TextFieldProps & Props> = ({ generateValue, ...props }) => {
  const [, , { setValue }] = useField(props.name)

  return (
    <TextField
      {...props}
      InputProps={{
        className: "p-0",
        endAdornment: (
          <Button
            color="secondary"
            variant="outlined"
            startIcon={<FaMagic className="h-4 w-4" />}
            onClick={() => setValue(generateValue())}
          >
            Generate
          </Button>
        ),
      }}
    />
  )
}
