import { TextField } from "@mui/material"
import { CircleF, GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { useField } from "formik"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { getGeocode } from "use-places-autocomplete"
import { LocationWithRadius } from "../../graphql/generated/client-types-and-hooks"
import { LatLongLiteral, LibraryTypes, MapType } from "../../types/Maps"
import { PageSection } from "../PageSection"
import { LocationDetails } from "./LocationDetails"
import { LocationSearch } from "./LocationSearch"
import { closeOptions } from "./MapOptions"
import { CENTER_OF_US, METERS_PER_MILE } from "./helpers"

const libraries: LibraryTypes = ["places"]

export const SearchableMap: FC<{ defaultLocation?: LocationWithRadius | undefined | null; locationNotes?: string }> = ({
  defaultLocation,
  locationNotes,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY as string,
    libraries,
  })

  if (!isLoaded) {
    return <div>Loading...</div>
  }

  if (!locationNotes)
    return (
      <div>
        <Map defaultLocation={defaultLocation} locationNotes={locationNotes} />
      </div>
    )
}

const options = {
  mapId: "73f498f1f672496",
  disableDefaultUI: true,
  clickableIcons: false,
  mapTypeId: "hybrid",
}

const Map: FC<{ defaultLocation?: LocationWithRadius | null | undefined; locationNotes?: string }> = ({
  defaultLocation,
  locationNotes,
}) => {
  const [{ value: addressValue }, _addressMeta, addressHelpers] = useField("address")
  const [{ value: locationValue }, _locationMeta, locationHelpers] = useField("location")
  const [{ value: notesValue }, _notesMeta, { setValue: setNotesValue }] = useField("locationNotes")
  const [map, setMap] = useState<MapType | null>(null)
  const [location, setLocation] = useState<LatLongLiteral | undefined>(
    defaultLocation ? { lat: defaultLocation.lat, lng: defaultLocation.lng } : undefined
  )
  const [userLocation, setUserLocation] = useState<LatLongLiteral>()
  const [nearestAddress, setNearestAddress] = useState("")
  const [radius, setRadius] = useState<number>(defaultLocation?.radius || Number((3 * METERS_PER_MILE).toFixed(0))) // radius is in meters

  const center = useMemo(() => {
    if (defaultLocation) {
      return { lat: defaultLocation.lat, lng: defaultLocation.lng }
    }
    return { lat: 40.2191667, lng: -111.7233611 }
  }, [defaultLocation])

  useEffect(() => {
    if (!userLocation && !defaultLocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = { lat: position.coords.latitude, lng: position.coords.longitude }
          setUserLocation(location)
          map?.panTo(location)
        },
        (err) => {
          console.error(err)
          setUserLocation(CENTER_OF_US)
        }
      )
    }

    if (locationNotes && !notesValue) {
      setNotesValue(locationNotes)
    }
  }, [userLocation, map, location, defaultLocation, locationNotes, notesValue, setNotesValue])

  useEffect(() => {
    if (!location) {
      if (Boolean(nearestAddress)) {
        setNearestAddress("")
        addressHelpers.setValue({})
      }
      return
    }

    const getNearestAddress = async () => {
      const [nearest] = await getGeocode({ location })

      // Convert to camelCase
      const addressData = {
        formattedAddress: nearest.formatted_address,
        addressComponents: nearest.address_components.map((comp) => ({
          longName: comp.long_name,
          shortName: comp.short_name,
          types: comp.types,
        })),
      }

      setNearestAddress(addressData.formattedAddress)
      addressHelpers.setValue(addressData)
    }

    getNearestAddress()
  }, [location, setNearestAddress, nearestAddress, addressHelpers])

  const onLoad = useCallback((map: MapType) => {
    setMap(map)
  }, [])

  const onUnmount = useCallback(() => {
    setMap(null)
  }, [])

  const pinAddressOrLocation =
    location?.lat && location?.lng && nearestAddress?.includes("+")
      ? `${location.lat}, ${location.lng}`
      : nearestAddress

  return (
    <PageSection>
      <div className="flex">
        <div className="w-full">
          <GoogleMap
            zoom={13}
            center={center}
            options={options}
            onLoad={onLoad}
            onUnmount={onUnmount}
            mapContainerClassName="w-full h-[500px]"
            onClick={(e) => {
              const coordinates = { lat: e.latLng?.lat() || center.lat, lng: e.latLng?.lng() || center.lng }
              locationHelpers.setValue({ ...locationValue, ...coordinates })
              setLocation(coordinates) // drop a pin here
              map?.panTo(coordinates) // center the map on this location
            }}
          >
            <LocationSearch
              pinAddressOrLocation={pinAddressOrLocation}
              radius={radius}
              setRadius={setRadius}
              setLocation={(position) => {
                locationHelpers.setValue({ ...locationValue, ...position })
                setLocation(position)

                if (!position) {
                  return
                }

                map?.panTo(position)
              }}
            />

            {location && (
              <>
                <MarkerF position={location} />
                <CircleF center={location} radius={radius} options={closeOptions} />
              </>
            )}
          </GoogleMap>

          <div className="flex-col grid gap-4 pt-4">
            <LocationDetails
              formattedAddress={addressValue?.formattedAddress}
              location={{ lat: location?.lat || 0, lng: location?.lng || 0, radius }}
              showNotes={false}
            />
            <div className="py-4">
              <TextField
                name="locationNotes"
                value={notesValue}
                onChange={(e) => setNotesValue(e.target.value)}
                fullWidth
                multiline
                placeholder="Location notes"
                rows={5}
              />
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  )
}
