import { Alert, TextField, Typography } from "@mui/material"
import { useField, useFormikContext } from "formik"
import { FC, useEffect, useMemo } from "react"
import { ScheduledBreak } from "../../graphql/generated/client-types-and-hooks"
import { Values } from "../../pages/projects/create/_index"
import { PickPlus } from "../../types/helpers"
import { Schedule, ScheduleDetails } from "../Partials/Organizations/TabPanels/ScheduleSubPanels/ScheduleDetails"
import { WorkDaysAndDates } from "./WorkDaysAndDates"

export type Holiday = { id: string; summary: string; start: { date: string | Date } }

type ScheduleBreak = PickPlus<
  ScheduledBreak,
  "id" | "durationInMinutes" | "isActive" | "localizedStartTime" | "name"
> & { breakTask: PickPlus<ScheduledBreak["breakTask"], "id" | "name"> }

export const ScheduleForm: FC<{
  schedule?: Schedule
  scheduledBreaks?: ScheduleBreak[]
  showManHours?: boolean
}> = ({ schedule, scheduledBreaks, showManHours = false }) => {
  const [{ value }, _meta, helpers] = useField("manHours")
  const [{ value: workDaysValue }] = useField("workDays")
  const { values } = useFormikContext<Values>()

  const showAlert = useMemo(() => {
    return values?.tasks?.some((task) => {
      return !!task.startDate || !!task.workDays
    })
  }, [values?.tasks])

  useEffect(() => {
    if (!schedule) {
      return
    }

    const workHours = schedule?.workHours?.hours

    if (workHours && workDaysValue) {
      helpers.setValue((workHours * (workDaysValue || 0)).toString())
    }
  }, [schedule, workDaysValue, value, helpers])

  return (
    <div>
      <div className="flex gap-x-2 py-4">
        <ScheduleDetails schedule={schedule} scheduledBreaks={scheduledBreaks} />
      </div>
      <div className="flex flex-col gap-x-2 py-4">
        <Typography className="border-b pb-2" fontSize={16} fontWeight={700}>
          Work Days
        </Typography>
        {showAlert && (
          <Alert severity="info" color="warning" sx={{ marginY: "12px", padding: "4px 16px" }}>
            Task start and end dates have been set with specific work days. The project will now default to using these
            task dates.
          </Alert>
        )}
        <div className="flex gap-x-2 mt-2 py-4">
          <WorkDaysAndDates schedule={schedule} disabled={showAlert} />
        </div>
        {showManHours && (
          <div className="flex gap-x-2 py-4">
            <TextField name="manHours" type="number" label="Man-Hours" value={value} fullWidth />
          </div>
        )}
      </div>
    </div>
  )
}
