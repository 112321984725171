import { Box, Button, Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { FC, useContext, useState } from "react"
import * as Yup from "yup"
import {
  Customer,
  Project,
  ScheduledBreak,
  ScheduleTemplate,
  Task,
  useCustomerListQueryQuery,
  useProjectEditMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { dataURLToBlob } from "../../../helpers/dataURLToBlob"
import { fileUpload } from "../../../helpers/fileUpload"
import { getDataUrlForImage } from "../../../helpers/getDataUrlForImage"
import { getGeneratedCode } from "../../../helpers/getGeneratedCode"
import { useHandleError } from "../../../hooks/useHandleError"
import { DevelopmentFeatureFlagContext } from "../../../providers/DevelopmentFeatureFlagProvider"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { PickPlus } from "../../../types/helpers"
import { MetadataNote } from "../../../types/MetadataNote"
import { DropDownMUI } from "../../DropDownMUI"
import { ButtonFilled, H2, H5 } from "../../Elements"
import { DatePicker } from "../../Formik/DatePicker"
import { MetadataNoteListInput } from "../../Formik/MetadataNoteInput"
import { MuiGenerateInput } from "../../Formik/MuiGenerateInput"
import { TextField } from "../../Formik/TextField"
import { WorkersCompCodeSelect } from "../../Formik/WorkersCompCodeSelect"
import { FormRow } from "../../FormRow"
import { ImageUpload } from "../../ImageUpload2"
import { SearchableMap } from "../../Maps/SearchableMap"
import { RenderIf } from "../../RenderIf"
import { ScheduleForm } from "../../Scheduling/ScheduleForm"
import { AddOrEditScheduleTemplateDrawer } from "./AddOrEditScheduleTemplateDrawer"
import { SingleDrawerContext } from "./Drawer"
import { DrawerBody } from "./DrawerBody"
import { DrawerFooter } from "./DrawerFooter"
import { DrawerHeader } from "./DrawerHeader"

type Values = {
  code: string
  customerId?: string
  description?: string
  endDate?: Date
  name: string
  startDate?: Date
  workersCompCodeId?: string
  metadata: MetadataNote[]
}

type Props = {
  project: PickPlus<
    Project,
    | "id"
    | "code"
    | "name"
    | "startDate"
    | "endDate"
    | "customerId"
    | "description"
    | "imageUrl"
    | "metadata"
    | "location"
    | "locationNotes"
    | "scheduleTemplate"
  > & {
    scheduledBreaks?: ProjectBreak[] | null | undefined
    customer?: PickPlus<Customer, "id" | "name"> | null | undefined
  }
}

type ProjectBreak = PickPlus<
  ScheduledBreak,
  "id" | "durationInMinutes" | "isActive" | "localizedStartTime" | "name" | "breakTaskId"
> & {
  breakTask: PickPlus<Task, "id" | "name">
}

export const ProjectEditDrawer: FC<Props> = ({ project }) => {
  const { handleClose } = useContext(SingleDrawerContext)
  const { push: pushDrawer } = useContext(DrawerContext)
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)

  const [{ data: customersData }] = useCustomerListQueryQuery()
  const customers = customersData?.customers || []

  const [projectImageSrc, setProjectImageSrc] = useState<string>("")
  const [initialProjectImageSrc, setInitialProjectImageSrc] = useState<string>(project.imageUrl || "")
  const [{ fetching: isLoading, error }, editProjectMutation] = useProjectEditMutation()

  useHandleError(error, "There was an error saving the project.")

  const [schedule, setSchedule] = useState<ScheduleTemplate | undefined>(project?.scheduleTemplate || undefined)
  const [projectBreaks, setProjectBreaks] = useState<ProjectBreak[]>(project?.scheduledBreaks || [])

  const onSaveSchedule = (schedule: ScheduleTemplate, scheduledBreaks: ScheduledBreak[]) => {
    setSchedule(schedule)
    setProjectBreaks(scheduledBreaks)
  }
  const handleEditSchedule = () =>
    pushDrawer(
      <AddOrEditScheduleTemplateDrawer
        handleSave={onSaveSchedule}
        schedule={project?.scheduleTemplate || undefined}
        scheduledBreaks={project?.scheduledBreaks || []}
      />
    )

  const onSubmit = async (values: Values) => {
    let image = !initialProjectImageSrc ? "" : undefined
    if (projectImageSrc) {
      const imageBlob = dataURLToBlob(projectImageSrc)
      const uploadedFile = await fileUpload(`project-image/${project.id}`, "image/webp", imageBlob)
      image = uploadedFile?.objectKey || undefined
    }

    const scheduledBreaks = projectBreaks?.map((scheduledBreak) => {
      const { breakTask, breakTaskId, ...scheduleBreak } = scheduledBreak

      return {
        ...scheduleBreak,
        breakTaskId: breakTaskId || breakTask?.id,
        id: scheduleBreak?.id || undefined,
      }
    })

    const inputs = {
      id: project.id,
      ...values,
      image,
      scheduledBreaks,
      schedule: { ...schedule, id: schedule?.id || undefined },
    }

    editProjectMutation(inputs).then((result) => {
      if (!result.error) handleClose()
    })
  }

  return (
    <Formik
      initialValues={{
        code: project.code || "",
        name: project.name,
        startDate: project.startDate ? project.startDate : undefined,
        endDate: project.endDate ? project.endDate : undefined,
        customerId: project.customer?.id ? project.customer?.id : "",
        description: project.description ? project.description : "",
        metadata: project.metadata || [{ label: "", content: "" }],
        location: project.location,
        locationNotes: project.locationNotes,
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string().trim().required("Required"),
        name: Yup.string().trim().required("Required"),
        startDate: Yup.date(),
        endDate: Yup.date().min(Yup.ref("startDate"), "Due date must be after Start date"),
        customerId: Yup.string(),
        description: Yup.string().trim().label("Project Description"),
        workersCompCodeId: Yup.string().nullable().label("Workers Comp Code"),
        metadata: Yup.array(
          Yup.object().shape({
            content: Yup.string().trim(),
            label: Yup.string().trim(),
          })
        ),
        location: Yup.object()
          .shape({
            lat: Yup.string(),
            lng: Yup.string(),
            radius: Yup.number(),
          })
          .nullable(),
        locationNotes: Yup.string().nullable(),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <DrawerHeader handleClose={handleClose} href={`/projects/${project.id}/details`} />

          <DrawerBody>
            <H2 className="mb-6 text-gray-800">Edit Project</H2>

            <H5 className="mt-10 mb-0">Basic Info</H5>
            <hr className="mt-2 mb-4 md:mb-6" />

            <FormRow childrenContainerClassName="flex-col mb-6">
              <ImageUpload
                onFileSelected={([file]) => {
                  if (file) {
                    getDataUrlForImage(file, (dataUrl) => setProjectImageSrc(dataUrl), 600, 600, "crop")
                  }
                }}
                onDelete={() => {
                  setProjectImageSrc("")
                  setInitialProjectImageSrc("")
                }}
                imageSrc={projectImageSrc}
                initialImageSrc={initialProjectImageSrc}
                accept="accept"
              />
            </FormRow>
            <FormRow>
              <TextField fullWidth name="name" label="Project name" />
            </FormRow>
            <FormRow>
              <MuiGenerateInput fullWidth name="code" label="Project code" generateValue={() => getGeneratedCode()} />
            </FormRow>

            <RenderIf permissionsInclude="timeEntry:export">
              <FormRow childrenContainerClassName="mb-6">
                <WorkersCompCodeSelect name="workersCompCodeId" />
              </FormRow>
            </RenderIf>
            <FormRow>
              <TextField fullWidth name="description" label="Project description" multiline rows={4} />
            </FormRow>

            {!flagIsEnabled("Org Scheduling") && (
              <FormRow>
                <Box width="100%" gap="20px" display="flex">
                  <DatePicker sx={{ width: "50%" }} label="Start Date" name="startDate" format="MM/dd/yyyy" />
                  <DatePicker sx={{ width: "50%" }} label="End Date" name="endDate" format="MM/dd/yyyy" />
                </Box>
              </FormRow>
            )}

            {flagIsEnabled("Customers") && (
              <>
                <H5 className="mt-10 mb-0">Contract Details</H5>
                <hr className="mt-2 mb-4 md:mb-6" />
                <FormRow>
                  <DropDownMUI
                    fieldName="customerId"
                    label="Customer"
                    items={[
                      {
                        value: "",
                        label: "None",
                      },
                      ...customers.map((customer) => ({
                        value: customer.id,
                        label: customer.name,
                      })),
                    ]}
                  />
                </FormRow>
              </>
            )}

            {flagIsEnabled("Org Scheduling") && (
              <>
                <div className="flex justify-between mt-8 md:col-span-12 border-b">
                  <Typography variant="h5">Schedule</Typography>
                  <Button color="primary" onClick={handleEditSchedule}>
                    Edit
                  </Button>
                </div>

                <div className="md:col-span-7 py-2">
                  <ScheduleForm schedule={schedule} scheduledBreaks={projectBreaks} />
                </div>
              </>
            )}

            <H5 className="mt-10 mb-0">Project Notes</H5>
            <hr className="mt-2 mb-4 md:mb-6" />

            <div className="col-span-2">
              <MetadataNoteListInput values={values.metadata} />
            </div>

            <div className="col-span-12 grid gap-y-2">
              <SearchableMap defaultLocation={project.location} />
            </div>

            <DrawerFooter>
              <div className="col-span-12 flex justify-start flex-col md:flex-row gap-4 md:gap-6 md:max-w-md ">
                <ButtonFilled color="primary" type="submit" disabled={isLoading || isSubmitting}>
                  {isSubmitting ? "Saving" : "Update"}
                </ButtonFilled>
                <Button variant="text" type="button" onClick={() => handleClose()} size="large">
                  Cancel
                </Button>
              </div>
            </DrawerFooter>
          </DrawerBody>
        </Form>
      )}
    </Formik>
  )
}
