import { FC } from "react"
import { LocationWithRadius } from "../../graphql/generated/client-types-and-hooks"
import { pluralize } from "../../helpers/pluralize"
import { PickPlus } from "../../types/helpers"
import { LabeledSection } from "../LabeledSection"
import { ValueOrNoneLabel } from "../Partials/Assets/BasicInfo"
import { METERS_PER_MILE } from "./helpers"

type Props = {
  formattedAddress?: string | null | undefined
  location: PickPlus<LocationWithRadius, "lat" | "lng" | "radius"> | null | undefined
  notes?: string | null
  showAddress?: boolean
  showCoordinates?: boolean
  showRadius?: boolean
  showNotes?: boolean
}

export const LocationDetails: FC<Props> = ({
  formattedAddress,
  location,
  notes = "",
  showAddress = true,
  showCoordinates = true,
  showRadius = false,
  showNotes = true,
}) => {
  const radius = ((location?.radius || 0) / METERS_PER_MILE).toFixed(1)

  return (
    <div className="pt-4 grid gap-y-4">
      {showAddress && (
        <LabeledSection label="Address" className="md:col-span-2 whitespace-pre-line">
          <ValueOrNoneLabel value={formattedAddress} />
        </LabeledSection>
      )}

      {showCoordinates && (
        <LabeledSection label="Coordinates" className="md:col-span-2 whitespace-pre-line">
          <ValueOrNoneLabel value={location?.lat && location?.lng ? `${location?.lat}, ${location?.lng}` : null} />
        </LabeledSection>
      )}

      {showRadius && (
        <LabeledSection label="Project Radius" className="md:col-span-2 whitespace-pre-line">
          <ValueOrNoneLabel value={`${radius} ${pluralize("Mile", Number(radius))}`} />
        </LabeledSection>
      )}

      {showNotes && (
        <LabeledSection label="Location Notes" className="md:col-span-2" note>
          <ValueOrNoneLabel value={notes} />
        </LabeledSection>
      )}
    </div>
  )
}
